import { Interval } from 'luxon';
import { getPersonIdFromSessionStorage } from '@/helpers/auth';
import { FeatureVariableKeys, useFeatureVariableValue } from '@/helpers/feature-provider';
import { sortMemberships } from '@/helpers/filter-courses';
import { diffFromToday, getCurrentDate, toDateTime } from '@/myphoenix/utils/date-time-functions';
import useGetProgramsByIdHook from '@/store/hooks/useGetProgramsByIdHook';
import { StudentCourseMembership } from '@/types/api/student/studentCourseMembership';
import { useGetMembershipsByPersonIdAndDateRangeQuery } from '@/store/queries/v2/student';
import {
  DAYS_TO_POST_UNTIL_DROPPED_AFTER_LAST_POST,
  DAYS_TO_SHOW_ATTENDANCE_ENGAGEMENT_AFTER_NO_POSTS,
  ENROLLMENT_PERIOD_IN_DAYS,
} from '@/components/organisms/cbeda-engagement/constants';

function UseGetCBEDAEngagementState() {
  const personId: string = getPersonIdFromSessionStorage();
  const blackOutUntil: string = useFeatureVariableValue(FeatureVariableKeys.DaEngagementTileBlackoutEndDate, 'N/A', false);
  const featureFlagOn = (!blackOutUntil
    || (blackOutUntil !== 'N/A' && diffFromToday(blackOutUntil) <= 0));
  const {
    isError: membershipError,
    isLoading: membershipLoading,
    data: memberships = [],
  } = useGetMembershipsByPersonIdAndDateRangeQuery(
    { personId },
    { skip: !featureFlagOn || !personId },
  ) as {
    isError: boolean,
    isLoading: boolean,
    data: StudentCourseMembership[],
  };
  const {
    data: { primaryProgram } = {},
    isFetching: programLoading,
    isError: programError,
  } = useGetProgramsByIdHook(personId, !featureFlagOn);
  const isError: boolean = membershipError || programError;
  const isLoading: boolean = membershipLoading || programLoading;
  const today = getCurrentDate();
  const sortedMembershipCurrentIds = sortMemberships(memberships)?.currentMembershipIds;
  const isCurrentCBEDAMembership = (membership: { sourceId?: string, offeringType?: string }) => {
    if (sortedMembershipCurrentIds) {
      return sortedMembershipCurrentIds.includes(membership.sourceId) && membership.offeringType === 'DA';
    }
    return false;
  };
  const currentCBEDAMemberships = memberships.filter(
    (membership: { sourceId?: string, offeringType?: string }) => (
      isCurrentCBEDAMembership(membership)
    ),
  );
  const membershipForTerm = currentCBEDAMemberships[0];
  const currentAcademicTerm = membershipForTerm?.daAcademicYearTerm;
  const isCBEDA = primaryProgram?.cbeDaProgram && primaryProgram.cbeDaProgram === 'TRUE';
  const lastActivityDate: string = primaryProgram?.lastActivityDate;
  const endOfToday = today.endOf('day');
  const lastPost = toDateTime(lastActivityDate);
  const currentTermStartDate = toDateTime(currentAcademicTerm?.termStartDate);
  const daysSinceTermStartDate = endOfToday.diff(currentTermStartDate, 'days').days;
  const postedToday = lastPost.hasSame(today, 'day');
  const postedInTermRange = Interval.fromDateTimes(currentTermStartDate, endOfToday);
  const postedInTermBeforeToday = postedInTermRange.contains(lastPost);
  const isWithinEnrollmentPeriod = daysSinceTermStartDate < ENROLLMENT_PERIOD_IN_DAYS;
  const enrollmentDaysLeft = Math.ceil(ENROLLMENT_PERIOD_IN_DAYS - daysSinceTermStartDate);
  const daysSinceLastPost = endOfToday.diff(lastPost, 'days').as('days');
  const daysLeftToPost = Math.ceil(DAYS_TO_POST_UNTIL_DROPPED_AFTER_LAST_POST - daysSinceLastPost);
  // eslint-disable-next-line max-len
  const isPastEnrollmentAndShouldShowAttendanceEngagement = ((!isWithinEnrollmentPeriod && postedInTermBeforeToday
    && (daysSinceLastPost >= DAYS_TO_SHOW_ATTENDANCE_ENGAGEMENT_AFTER_NO_POSTS
      && daysSinceLastPost <= DAYS_TO_POST_UNTIL_DROPPED_AFTER_LAST_POST))
    || (!isWithinEnrollmentPeriod && postedToday));
  const showCbeDaEngagementTile = (featureFlagOn
    && isCBEDA && !!currentCBEDAMemberships
    && !(isLoading || isError));
  return {
    showCbeDaEngagementTile,
    isWithinEnrollmentPeriod,
    postedInTermBeforeToday,
    postedToday,
    enrollmentDaysLeft,
    lastActivityDate,
    isPastEnrollmentAndShouldShowAttendanceEngagement,
    daysLeftToPost: isWithinEnrollmentPeriod ? enrollmentDaysLeft : daysLeftToPost,
  };
}
export default UseGetCBEDAEngagementState;

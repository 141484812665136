import { Grid, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Carousel from 'react-material-ui-carousel';
import ResourceCard, { ResourceCardProps } from '@/components/atoms/resource-card';
import { componentNameToMyPhxKebabFormat } from '@/helpers/string-utils';
import config from '@/helpers/config';

const { careers, ecampus, multimedia, myphx, Portal } = config;

export type ResourceCardGroupProps = {
  content?: ResourceCardProps[],
};

const defaultContent = [
  {
    title: 'Library',
    links: [
      { text: 'University Library', href: 'https://library.phoenix.edu/' },
      { text: 'Student Resources', href: `${multimedia.baseUrl}cms/student-resources/` },
      { text: 'Writing Center', href: `${multimedia.baseUrl}cms/cwe/` },
      { text: 'Citation Generator', href: `${multimedia.baseUrl}cms/citation` },
    ],
  },
  {
    title: 'Career',
    links: [
      { text: 'Career Navigator', href: `${careers.baseUrl}` },
      { text: 'Job Explorer', href: `${careers.baseUrl}job-explorer/jobs` },
      { text: 'Career Advising', href: `${careers.baseUrl}advising` },
      { text: 'Career Resources', href: `${myphx.baseUrl}/vsu/career-services.html` },
    ],
  },
  {
    title: 'Services',
    links: [
      { text: 'Prior Learning Assessment', href: `${process.env.NEXT_PUBLIC_BASE_URL}/pla.html` },
      { text: 'Order Transcript', href: `${ecampus.baseUrl}/Portal/Portal/Public/Login.aspx?ReturnUrl=${encodeURIComponent(`${Portal}/te/transcripts/index.html`)}` },
      { text: 'ADA Accommodation', href: `${multimedia.baseUrl}cms/ada-accommodation-request` },
      { text: 'Letter Center', href: `${ecampus.baseUrl}Program/LetterCenter` },
    ],
  },
];

function ResourceCardGroup({
  content = defaultContent,
}: ResourceCardGroupProps) {
  const componentName = 'ResourceCardGroup';
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    isMobile ? (
      <Carousel
        autoPlay={false}
        animation="slide"
        data-component={componentNameToMyPhxKebabFormat('ResourceCardGroupMobile')}
        navButtonsAlwaysInvisible
        IndicatorIcon={(
          <span style={{
            height: theme.spacing(1.6),
            width: theme.spacing(1.6),
            borderRadius: '50%',
          }}
          />
        )}
        indicatorIconButtonProps={{
          style: {
            marginRight: theme.spacing(1.6),
            backgroundColor: theme.palette.almostBlack.l70,
          },
        }}
        activeIndicatorIconButtonProps={{
          style: {
            backgroundColor: theme.palette.almostBlack.l30,
          },
        }}
        indicatorContainerProps={{
          style: {
            marginTop: theme.spacing(2.4),
            fontSize: theme.spacing(1.6),
            paddingLeft: theme.spacing(1.6),
          },
        }}
      >
        {content.map((card) => (
          <ResourceCard
            parentComponentName={componentName}
            key={card.title}
            title={card.title}
            links={card.links}
          />
        ))}
      </Carousel>
    ) : (
      <Grid data-component={componentNameToMyPhxKebabFormat('ResourceCardGroupNonMobile')} container spacing={1} sx={{ width: '100%', flexWrap: 'nowrap' }}>
        {content.map((card) => (
          <Grid key={card.title} item sm={4}>
            <ResourceCard
              parentComponentName={componentName}
              title={card.title}
              links={card.links}
            />
          </Grid>
        ))}
      </Grid>
    )
  );
}
export default ResourceCardGroup;

import useGetAttendanceTileState from './useGetAttendanceTile.state';
import AttendanceTile from './AttendanceTile.ui';

function AttendanceTileContainer({
  parentComponentName = '',
}: {
  parentComponentName?: string
}) {
  // Keep the same data component names as MYPHX Static to honor the FS queries
  const componentName: string = parentComponentName ? `${parentComponentName}AttendanceTile` : 'AttendanceTileV2';

  const { courseTemplateCode,
    totalAbsences,
    recentPost,
    isFirstWeekOfCourse,
    dayOfWeek,
    displayDonut,
    hideTooltip,
    isFetching,
    isError,
    shouldShowAttendanceTile,
    classRoomLink,
    isInDangerZone,
    daysLeft,
    errorTrackingMessage } = useGetAttendanceTileState();
  return shouldShowAttendanceTile
    ? (
      <AttendanceTile
        componentName={componentName}
        courseTemplateCode={courseTemplateCode}
        totalAbsences={totalAbsences}
        recentPost={recentPost}
        isFirstWeekOfCourse={isFirstWeekOfCourse}
        dayOfWeek={dayOfWeek}
        displayDonut={displayDonut}
        hideTooltip={hideTooltip}
        isFetching={isFetching}
        isError={isError}
        classRoomLink={classRoomLink}
        isInDangerZone={isInDangerZone}
        daysLeft={daysLeft}
        errorTrackingMessage={errorTrackingMessage}
      />
    )
    : null;
}

export default AttendanceTileContainer;

import { Grid, Theme, useMediaQuery } from '@mui/material';
import { componentNameToMyPhxKebabFormat } from '@/helpers/string-utils';
import { Body, Heading, StyledButton, BodyHeading, BodyContent } from '@/components/molecules/b2b-interrupter/B2bInterrupter.styles';

function B2bInterrupter({
  parentComponentName = '',
}: {
  parentComponentName?: string
}) {
  const componentName = parentComponentName ? `${parentComponentName}B2bInterrupter` : 'B2bInterrupter';
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const B2bCTA = 'https://my.phoenix.edu/alliance-benefits';

  return (
    <Body container direction="column" data-component={componentNameToMyPhxKebabFormat(componentName)}>
      <Grid container item direction="row" justifyContent="space-between">
        <Grid item md={6}>
          <Heading variant="h5" component="h3">Your employer + UOPX</Heading>
        </Grid>
        <Grid item>
          {!isMobile && <StyledButton href={B2bCTA}>Maximize your benefits</StyledButton>}
        </Grid>
      </Grid>
      <Grid container direction="column">
        <BodyHeading variant="body4">
          {/* eslint-disable-next-line max-len */}
          Make the most of your alliance benefits. You could save thousands on tuition!
        </BodyHeading>
        <BodyContent variant="body4">
          Unlock tuition waivers thanks to the relationship between UOPX and your employer.
        </BodyContent>
        {isMobile && <StyledButton href={B2bCTA}>Maximize your benefits</StyledButton>}
      </Grid>
    </Body>
  );
}

export default B2bInterrupter;
